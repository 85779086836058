@import "../../../_theme_variables";

#header-login {
	font-size:14px;
	line-height:26px;
	.header_user_info {
		padding: 10px 0;
		border-bottom:1px solid #aeaeae;
		width:100%;
		background: $dark-background;
		a {
			font: normal 16px/22px $font-custom;
			text-transform:uppercase;
			padding: 8px 50px 9px 15px;
			color:#888;

			&:before {
				content: "\e000";
				font-family: $flat-icon;
				display: inline-block;
				font-size: 24px;
				color: $light-text-color;
				padding-right: 9px;
				vertical-align:-4px;
				@include transition (.3s);
			}
			&:after {
				content: "\e071";
				font-family: $flat-icon;
				position:absolute;
				right:0;
				top:0;
				height:40px;
				width:47px;
				font-size:24px;
				line-height:40px;
				display:block;
				color:#aeaeae;
				cursor:pointer;
				@include transition (.3s);
			}
			&:hover {
				color:#fff;
				&:after {
					color:#fff;
				}
			}
		}
		&.current {
			position:relative;
			padding-bottom:4px;
			a {
				&:after {
					content: "\e071";
					font-family: $flat-icon;
					position:absolute;
					right:0;
					top:0;
					height:40px;
					width:47px;
					font-size:24px;
					line-height:40px;
					display:block;
					color:#aeaeae;
					cursor:pointer;
					@include transition (.3s);
				}
			}
			&.active a:after {
				content: "\e073";
			}
		}


	}
	.logout {
		overflow:hidden;
		margin-bottom:0;
	}	
}

/*
	> a:first-child {
		&:after {
			content: "\e071";
			font-family: $flat-icon;
			position:absolute;
			right:0;
			top:0;
			height:40px;
			width:47px;
			font-size:24px;
			line-height:40px;
			display:block;
			color:#aeaeae;
			cursor:pointer;
			@include transition (.3s);
		}
	}
	&:hover, &.activeBox {
		> a:first-child {
			color:#fff;
			b, &:before { color:#888;}
			&:after {
				color:#fff;
			}
		}
	}
	&.activeBox {
		> a:first-child {
			&:after {
				content: "\e073";
			}
		}
	}


*/



#header-login-content {
	display:none;
	width:100%;
	padding:19px 29px 20px 31px;
	margin:0;
	z-index:1;
	background:#f2f2f2;
	color:#fff;
/*
	border:1px solid #f2f2f2;
	-webkit-box-shadow: inset 0 0 0 2px white;
	-moz-box-shadow: inset 0 0 0 2px white;
	box-shadow: inset 0 0 0 2px white;
*/
	label {
		color:#fff;	
	}
	li {
		margin:0;
		padding:0;
		#header_login_form {
			.form-group {
				margin-bottom:11px;	
			}
			.account_input {
				height:50px;
				border:none;
				border-bottom:1px solid #fff;
				background:none;
				/*
				padding:14px 0;
				*/
				font-size:14px;
			}
		}
		.form_content {
			p {
				width:50%;
				float:left;
				padding-top:19px;
				button, a {
					display:block;
					float:none;
					font-size:21px;
					height:50px;
					background:#262626;
					color:#888;
					border:none;
					text-align:center;
					width:100%;
					&:hover {
						color:#fff;	
					}
					&:before {
						content: "\e0d0";
						font-family: $flat-icon;
					}
					span {
						display:none;	
					}
				}
				a {
					margin-left:1px;
					font-size:22px;
					padding-top:12px;
					&:before {
						content: "\e006";
					}	
				}
			}
		}
		ul {
			margin:0 0 10px 0;
			li {
				display:block;
				border-top:1px solid #ccc;
				font-size:14px;
				line-height:40px;
				&:first-child {border:none;}
				a {
					color:#888;
					padding:0;
					display:block;
					&:hover {
						color:#1c1c1c;
					}
					i {display:none;}
				}
			}
		}
	}
}

#create_header_account_error {
	padding:8px;	
}